import React from 'react';
import Section01details from '../components/details/Section01';
import Section02details from '../components/details/Section02';
import Section03details from '../components/details/Section03';
import Layout from "../components/layout"
import Seo from "../components/seo"

//Base Web

const details: React.FC = () => {
    return (
        <Layout>
            <Seo 
            title={"Descubre cuáles son las funcionalidades de Evofinder "}
            description={"Permite que el investigador tome decisiones acertadas y más inteligentes de forma rápida con funcionalidades 3D, análisis automática, análisis visual"} 
            />
            <Section01details />
            <Section02details />
            <Section03details />
        </Layout>
    );
};

export default details;
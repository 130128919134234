import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Video from "../home/video"


const Section03details: React.FC = () => {
    return (
        <section className="container   max-w-2xl 2xl:max-w-4xl mx-auto pt-14 md:pt-32 lg:pt-20 2xl:pt-28 px-8 font-body text-gray">
            <Video
                className="container   max-w-2xl 2xl:max-w-4xl mx-auto"
                videoSrcURL="https://www.youtube.com/embed/7qjT6l292Ec"
                videoTitle="Más características de Evofinder"
            />


            <div className="grid grid-cols-1 md:grid-cols-2 pt-20">
                <div className="font-body text-gray md:mr-2.5">
                    <h2 className="text-heading-2 -ml-0.5 py-4">Evofinder®3D Technology</h2>
                    <p className="text-base pt-9">
                        La tecnología Evofinder® 3D es una respuesta a varias consultas sobre la
                        posibilidad de los sistemas Evofinder para crear imágenes en 3D.
                        <br></br>
                        ¿Cuál fue el objetivo de desarrollar la tecnología Evofinder® 3D?
                    </p>
                    <p className="flex justify-start"><a className="inline-flex text-red pt-4" href="http://evofinder.com/technology/2dd/"  target="_blank">Más sobre Evofinder®3D</a></p>
                </div>
                <div className="pt-6 flex justify-center  md:ml-2.5">
                    <StaticImage
                        alt="evofinder 3dr"
                        src="../../images/evofinder3d.png"
                        quality={75}
                        imgStyle={{ objectFit: 'contain' }}

                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 pt-24">
                <div className="font-body text-gray md:mr-2.5">
                    <h2 className="text-heading-2 -ml-0.5 py-4">Sistema de análisis de muestras (SAS)</h2>
                    <p className="text-base pt-9">
                        SAS proporciona un procedimiento de copia de seguridad automatizado para evitar la pérdida de datos .
                    </p>
                    <p className="flex justify-start"><a className="inline-flex text-red pt-4" href="http://evofinder.com/technology/specimen-analysis-system-sas/" target="_blank">Más sobre Sistema de análisis de muestras</a></p>
                </div>
                <div className="pt-6 flex justify-center  md:ml-2.5">
                    <StaticImage
                        alt="Evofinder SAS"
                        src="../../images/evofinderdsas.png"
                        quality={75}
                        imgStyle={{ objectFit: 'contain' }}
                        formats={["AUTO", "WEBP", "AVIF"]}

                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 pt-24">
                <div className="font-body text-gray md:mr-2.5">
                    <h2 className="text-heading-2 -ml-0.5 py-4 ">Estación de trabajo Experta (EWS)</h2>
                    <p className="text-base pt-9">
                        EWS proporciona una lista de resultados de objetos previamente guardados en la base de datos y similares al objeto bajo examen.
                    </p>
                    <p className="flex justify-start"><a className="inline-flex text-red pt-4" href="http://evofinder.com/technology/expert-working-station-ews/#1" target="_blank">Más sobre Estación de trabajo Experta</a></p>
                </div>
                <div className="pt-6 flex justify-center  md:ml-2.5">
                    <StaticImage
                        alt="Evofinder ews"
                        src="../../images/evofinderews.png"
                        quality={75}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        imgStyle={{ objectFit: 'contain' }}

                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 pt-24">
                <div className="font-body text-gray md:mr-2.5">
                    <h2 className="text-heading-2 -ml-0.5 py-4">La Solución De Red</h2>
                    <p className="text-base pt-9">
                        El sistema Evofinder® desde el inicio de su desarrollo se considera un sistema
                        basado en red.

                        La solución específica a bordo permite utilizar y analizar datos de
                        sistemas similares ubicados en diferentes laboratorios mediante la comunicación
                        entre los sistemas, es decir, sus servidores.
                    </p>
                    <p className="flex justify-start"><a className="inline-flex text-red pt-4" href="http://evofinder.com/wan-solution/" target="_blank">Más sobre interconexión</a></p>
                </div>
                <div className="pt-6 flex justify-center  md:ml-2.5">
                    <StaticImage
                        alt="Evofinder Red"
                        src="../../images/evofinderred.png"
                        quality={75}
                        imgStyle={{ objectFit: 'contain' }}
                        formats={["AUTO", "WEBP", "AVIF"]}
                    />
                </div>
            </div>
        </section>
    )
}

export default Section03details

import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video">
    <h2 className="self-center text-center md:max-w-screen-lg text-heading-4 pb-2">Más características de Evofinder</h2>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      width="100%" height="315" 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video
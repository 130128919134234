import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const Section01details: React.FC = () => {
    return (
        <section className="container   max-w-4xl 2xl:max-w-6xl mx-auto pt-14 md:pt-32 lg:pt-20 2xl:pt-28 px-8 font-body text-gray">
            <div>
                <h1 className="self-center text-center md:max-w-screen-lg text-heading-2 font-body">¿Por qué elegir Evofinder®?</h1>
                <p className="text-base pt-12">Permite al investigador tomar decisiones acertadas y más inteligentes de forma rápida con la tecnología <a className="text-red" href="http://evofinder.com/technology/data-acquisition-station-das/" target="_blank">“frame-fragment"</a> de Evofinder® 3D technology, análisis automático, análisis visual y las mejores prácticas, basados en las normas nacionales e internacionales, con la velocidad de misión crítica.
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 pt-10">
                <div className="pt-6 flex justify-center md:ml-2.5 w-full">
                    <StaticImage
                        alt="Escaneo evofinder 3d"
                        src="../../images/escaneo-evofinder-3d.jpg"
                        quality={71}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        imgStyle={{ objectFit: "contain" }}
                    />
                </div>
                <div className="font-body text-gray md:mr-2.5 md:mx-4 lg:px-8">
                    <h2 className="text-heading-4 -ml-0.5 py-4">Impulse su laboratorio con las funcionalidades de Evofinder</h2>
                    <p className="text-base">
                    Prioridad en los los resultados de las investigaciones, optimiza los procesos, aumenta la agilidad de planificación y mejora el tiempo de respuesta en la investigación de huellas balísticas. Utilice Evofinder para aprovechar la última tecnología en 3D.
                    </p>
                    <div className="text-center text-white my-4 py-2 text-base bg-green">
                        <a
                            href="https://calendar.google.com/calendar/selfsched?sstoken=UU5PQWlwRjBDRC1ifGRlZmF1bHR8N2EzNmM3NmQyZTlmMWRiMzMwYmMwNGViNDg4YzBlMGM"
                            target="_blank"
                            className="font-body ">
                            Solicitar una demostración
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section01details

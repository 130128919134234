import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const Section02details: React.FC = () => {
    return (
        <section className="container flex flex-col justify-items-center max-w-8xl 2xl:max-w-8xl mx-auto pt-14 md:pt-32 lg:pt-20 2xl:pt-28 px-8 font-body text-gray">
            <h2 className="self-center text-heading-3 md:max-w-screen-lg text-heading-2">Explore todas las funcionalidades</h2 >
            <div className="grid grid-cols-1 md:grid-cols-3 pt-24">
                <div className="text-center justify-center mx-5">
                    <StaticImage
                        alt="Evofinder Diseño compacto"
                        src="../../images/evofinder-equipo-compacto.jpg"
                        quality={70}
                        imgStyle={{ objectFit: 'contain' }}
                        width={150}
                    />
                    <h2 className="text-heading-4 pt-10">DISEÑO COMPACTO</h2>
                    <p className="text-left text-base pt-10">
                    Evofinder es un equipo de tamaño pequeño, lo que permite que el área de trabajo que requiere sea menor al requerido por equipos similares que pueden ocupar hasta hasta 10 a 12 m² de su espacio de trabajo.</p>
                </div>
                <div className="text-center justify-center mx-5">
                    <StaticImage
                        alt="Evofinder escaneo 9mm"
                        src="../../images/escaneo-9mm.jpg"
                        quality={70}
                        imgStyle={{ objectFit: 'contain' }}
                        width={150}
                    />
                    <h2 className="text-heading-4 pt-10">GRAN RENDIMIENTO</h2>
                    <p className="text-left text-base pt-10">
                    Registre bala, casquillo y fragmentos en un solo equipo. Evofinder trabaja de forma eficiente, por ejemplo, realiza el registro de una bala de 9mm en menos de 3 minutos.
                    </p>
                </div>
                <div className="text-center justify-center mx-5">
                    <StaticImage
                        alt="Equipo Evofinder"
                        src="../../images/evofinder-mas-resultados.jpg"
                        quality={70}
                        imgStyle={{ objectFit: 'contain' }}
                        width={150}

                    />
                    <h2 className="text-heading-4 pt-10">MÁS RESULTADOS</h2>
                    <p className="text-left text-base pt-10">
                        Reduce el número de no concluyentes gracias a la información en 3D, permite tener intercambios interlaboratorios con archivos topográficos en 3D de alta resolución con detalle visual más que un LCM.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Section02details
